// import React, { useEffect, useState } from "react";
// import { SpinnerImg } from "../../loader/Loader";
// import "../productList/productList.scss";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   FILTER_NEW_SALES,
//   selectFilteredNewSales,
// } from "../../../redux/features/product/filterSlice";
// import {
//   CALC_PROFIT_VALUE,
//   CALC_SOLD_VALUE,
//   generateReceipt,
//   getCheckouts,
//   printReceipt,
//   updateDeliveryStatus,
// } from "../../../redux/features/cart/cartSlice";
// import ReactPaginate from "react-paginate";
// import "react-confirm-alert/src/react-confirm-alert.css";
// import moment from "moment";
// import { Link } from "react-router-dom";
// import arrowDown from "../../../assets/home/arrowdown.svg";
// import arrowUp from "../../../assets/home/arrowUp.svg";
// import downloadIcon from "../../../assets/home/downloadIcon.svg";
// import printIcon from "../../../assets/home/printIcon.svg";
// import shareIcon from "../../../assets/home/shareIcon.svg";
// import whatsappIcon from "../../../assets/home/whatsapp-icon2.svg";
// import emailIcon from "../../../assets/home/email-icon2.svg";
// import returnIcon from "../../../assets/home/return.svg";
// import "./salesList.css";
// import DatePicker from "../../datePicker/DatePicker";
// import InventoryHeader from "../../inventoryHeader/InventoryHeader";
// import { Button, Popover, Tooltip } from "antd";
// import { selectUser } from "../../../redux/features/auth/authSlice";
// import ReturnFunction from "./ReturnFunction";
// import StatisticsHeader from "../../inventoryHeader/StatisticsHeader";
// import {
//   sendReceiptEmail,
//   shareReceipt,
//   verifyBusinessEmail,
// } from "../../../services/authService";
// import { toast } from "react-toastify";
// import axios from "axios";
// import fileDownload from "js-file-download";
// // import printReceipt from "../../../utils/print.ts";

// const NewSalesList = ({ isLoading, admin, checkouts }) => {
//   const dispatch = useDispatch();
//   const [search, setSearch] = useState("");
//   const filteredNewSales = useSelector(selectFilteredNewSales);
//   const currentUser = useSelector(selectUser);
//   const [showBody, setShowBody] = useState({});
//   const [returnModal, setReturnModal] = useState(false);
//   const [openPopoverId, setOpenPopoverId] = useState(null);
//   const [popOverCurrentSale, setPopOverCurrentSale] = useState(null);
//   const [pageRangeDisplayed, setPageRangeDisplayed] = useState(3);
//   const [verifyingEmail, setVerifyingEmail] = useState(false);
//   const handleOpenChange = (newOpen, id) => {
//     setOpenPopoverId(newOpen ? id : null);
//   };
//   const [deliveryStatusDropdown, setDeliveryStatusDropdown] = useState(null);

//   // console.log("currentUser", currentUser);

//   const hide = () => {
//     setOpenPopoverId(null);
//   };

//   function handleShowBody(id) {
//     setShowBody((prev) => {
//       const newState = { ...prev };
//       newState[id] = !newState[id];
//       return newState;
//     });
//   }

//   useEffect(() => {
//     dispatch(CALC_SOLD_VALUE(checkouts));
//     dispatch(CALC_PROFIT_VALUE(checkouts));
//   }, [dispatch, checkouts]);

//   const shortenText = (text, n) => {
//     if (text.length > n) {
//       const shortenedText = text.substring(0, n).concat("...");
//       return shortenedText;
//     }
//     return text;
//   };

//   //   Begin Pagination
//   const [currentItems, setCurrentItems] = useState([]);
//   const [pageCount, setPageCount] = useState(0);
//   const [itemOffset, setItemOffset] = useState(0);
//   const itemsPerPage = 10;

//   useEffect(() => {
//     const endOffset = itemOffset + itemsPerPage;

//     setCurrentItems(filteredNewSales.slice(itemOffset, endOffset));
//     setPageCount(Math.ceil(filteredNewSales.length / itemsPerPage));
//   }, [itemOffset, itemsPerPage, filteredNewSales]);

//   const handlePageClick = (event) => {
//     const newOffset = (event.selected * itemsPerPage) % filteredNewSales.length;
//     setItemOffset(newOffset);
//   };

//   useEffect(() => {
//     dispatch(FILTER_NEW_SALES({ checkouts, search }));
//   }, [checkouts, search, dispatch]);

//   const formatter = (amount) => {
//     return new Intl.NumberFormat("en-US", {
//       style: "currency",
//       currency: "NGN",
//     }).format(amount);
//   };

//   const sendReceiptToPrinter = (id, sale) => {};

//   const sendReceiptToEmail = async (sale) => {
//     const email = currentUser?.email;
//     if (!email) {
//       return;
//     }

//     // console.log("PopOverr id", openPopoverId);
//     // console.log("PopOverr current sale", sale);

//     const formData = {
//       toEmail: sale.customer.email,
//       sale: sale,
//     };

//     handleOpenChange(false, openPopoverId);
//     setVerifyingEmail(true);
//     try {
//       handleOpenChange(false, openPopoverId);
//       const response = await sendReceiptEmail(formData);
//       setVerifyingEmail(false);
//       toast.success(response.message);
//       console.log("response", response);
//     } catch (error) {
//       setVerifyingEmail(false);
//       console.log("error", error);
//     }

//     // window.open(`mailto:${email}?subject=${subject}&body=${body}`);
//   };

//   const verifyEmailSendGrid = async () => {
//     setVerifyingEmail(true);
//     try {
//       const data = await verifyBusinessEmail();
//       toast.success("Kindly check your email to verify your email address");
//       handleOpenChange(false, openPopoverId);
//       setVerifyingEmail(false);
//     } catch (error) {
//       setVerifyingEmail(false);
//       toast.error(error.message);
//       console.log("error", error);
//     }
//   };

//   const shareReceiptContent = (sale) => {
//     const message = `Thank you for your purchase!\n\nPlease find your receipt attached.`;

//     const shareViaWhatsApp = async () => {
//       handleOpenChange(false, openPopoverId);
//       // setVerifyingEmail(true);
//       try {
//         const receipt = await shareReceipt(sale, sale._id);

//         const receiptFile = new File([receipt], "receipt.pdf", {
//           type: "application/pdf",
//         });

//         // console.log("receiptFile", receiptFile);
//         if (
//           navigator.canShare &&
//           navigator.canShare({ files: [receiptFile] })
//         ) {
//           await navigator.share({
//             title: "Sales Receipt",
//             text: message,
//             files: [receiptFile],
//           });
//         } else {
//           // Fallback for unsupported browsers
//           toast.error("File sharing is not supported on this device.");
//         }
//       } catch (error) {
//         console.log("Error sharing:", error);
//         toast.error("Failed to share the receipt.");
//       }
//     };

//     return (
//       <div className="share-receipt-content">
//         <span>
//           Verify your email to send emails, click{" "}
//           <span onClick={verifyEmailSendGrid}>here</span>
//         </span>
//         <a
//           onClick={() => sendReceiptToEmail(sale)}
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <img src={emailIcon} alt="email" />
//           <span className="share_text">Email</span>
//         </a>
//         <a onClick={shareViaWhatsApp} target="_blank" rel="noopener noreferrer">
//           <img src={whatsappIcon} alt="whatsapp" />
//           <span className="share_text">WhatsApp/Other channels</span>
//         </a>
//       </div>
//     );
//   };

//   const handleChangeDeliveryStatus = async (id, newStatus) => {
//     const formData = {
//       id: id,
//       status: newStatus,
//     };

//     if (currentUser?.permissions?.sellProducts) {
//       try {
//         const response = await dispatch(updateDeliveryStatus(formData));
//         // console.log("response", response.meta.requestStatus);
//         dispatch(getCheckouts({}));
//         toast.success("Delivery status updated successfully.");
//         setDeliveryStatusDropdown(null);
//       } catch (error) {
//         console.log("error", error);
//         toast.error("Failed to update delivery status.");
//       }
//     } else {
//       toast.error("You do not have permission to update delivery status.");
//     }
//   };

//   const toggleDeliveryStatusDropdown = (id) => {
//     setDeliveryStatusDropdown((prev) => (prev === id ? null : id));
//   };

//   const handleDetectMobileScreen = () => {
//     if (window.innerWidth <= 500) {
//       setPageRangeDisplayed(2); // For mobile devices
//     } else {
//       setPageRangeDisplayed(3); // For larger screens
//     }
//   };

//   useEffect(() => {
//     handleDetectMobileScreen(); // Set initial value
//     window.addEventListener("resize", handleDetectMobileScreen);
//     return () => {
//       window.removeEventListener("resize", handleDetectMobileScreen);
//     };
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         deliveryStatusDropdown &&
//         !event.target.closest(".delivery-status-options") &&
//         !event.target.closest(".delivery-status-dropdown")
//       ) {
//         setDeliveryStatusDropdown(null);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);

//     // Cleanup the event listener on component unmount
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [deliveryStatusDropdown]);

//   return (
//     <>
//       <div className="product-list sales-page">
//         <div className="table">
//           <InventoryHeader
//             search={search}
//             handleSearchChange={(e) => setSearch(e.target.value)}
//           />
//           {returnModal && (
//             <div className="return_container">
//               <ReturnFunction
//                 handleCancel={() => {
//                   setReturnModal(!returnModal);
//                 }}
//                 items={currentItems}
//                 admin={admin}
//                 currentUser={currentUser}
//               />
//             </div>
//           )}

//           <StatisticsHeader
//             checkouts={checkouts}
//             admin={admin}
//             currentUser={currentUser}
//           />

//           {isLoading && <SpinnerImg />}
//           {verifyingEmail && <SpinnerImg />}

//           <div className="">
//             {!isLoading && checkouts.length === 0 ? (
//               <p className="no-products-p">
//                 -- All checkouts will appear here...
//               </p>
//             ) : (
//               <table className="salesList-table">
//                 <thead>
//                   <tr>
//                     <th>Id</th>
//                     <th>Date</th>
//                     <th>Items</th>
//                     <th>Customer</th>
//                     <th>Payment Type</th>
//                     <th>Status</th>
//                     <th>Total</th>
//                     <th>Delivery</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentItems?.map((sale, index) => {
//                     const {
//                       customer,
//                       items,
//                       user,
//                       createdAt,
//                       _id,
//                       payment,
//                       deliveryStatus,
//                       orderId,
//                     } = sale;
//                     const format = "DD-MM-YYYY h:mmA";
//                     const formattedDate = moment(createdAt).format(format);
//                     const formattedDeliveryDate = moment(
//                       deliveryStatus.date
//                     ).format(format);
//                     const totalAmount = items.reduce(
//                       (total, item) => total + item.price * item.quantity,
//                       0
//                     );
//                     return (
//                       <React.Fragment key={index}>
//                         <tr className={showBody[_id] ? "show_background" : ""}>
//                           <td onClick={() => handleShowBody(_id)}>
//                             {orderId ? orderId : index + 1}
//                           </td>
//                           <td onClick={() => handleShowBody(_id)}>
//                             <div className="item_s_date">{formattedDate}</div>
//                           </td>
//                           <td onClick={() => handleShowBody(_id)}>
//                             {items.length}
//                           </td>
//                           <td>
//                             <div className="item_name">
//                               {customer?.name && (
//                                 <Tooltip title={customer?.name}>
//                                   {shortenText(customer?.name, 14)}
//                                 </Tooltip>
//                               )}
//                             </div>
//                           </td>

//                           <td onClick={() => handleShowBody(_id)}>
//                             <div className="item_name">
//                               {payment?.paymentType}
//                             </div>
//                           </td>
//                           <td onClick={() => handleShowBody(_id)}>
//                             {payment?.paymentStatus}
//                           </td>
//                           <td>
//                             <div className="item_name">
//                               {formatter(totalAmount)}
//                             </div>
//                           </td>
//                           <td>
//                             <div
//                               className="delivery-status-dropdown"
//                               onClick={() => toggleDeliveryStatusDropdown(_id)}
//                             >
//                               {deliveryStatus?.status === "pickedup"
//                                 ? "picked up"
//                                 : deliveryStatus?.status}
//                               <span>{formattedDeliveryDate}</span>
//                               {deliveryStatusDropdown === _id && (
//                                 <div className="delivery-status-options">
//                                   <div
//                                     onClick={() =>
//                                       handleChangeDeliveryStatus(_id, "pending")
//                                     }
//                                     className={
//                                       deliveryStatus?.status === "pending"
//                                         ? "option active"
//                                         : "option"
//                                     }
//                                   >
//                                     Pending
//                                   </div>
//                                   <div
//                                     onClick={() =>
//                                       handleChangeDeliveryStatus(
//                                         _id,
//                                         "delivered"
//                                       )
//                                     }
//                                     className={
//                                       deliveryStatus?.status === "delivered"
//                                         ? "option active"
//                                         : "option"
//                                     }
//                                   >
//                                     Delivered
//                                   </div>
//                                   <div
//                                     onClick={() =>
//                                       handleChangeDeliveryStatus(
//                                         _id,
//                                         "pickedup"
//                                       )
//                                     }
//                                     className={
//                                       deliveryStatus?.status === "pickedup"
//                                         ? "option active"
//                                         : "option"
//                                     }
//                                   >
//                                     Picked up
//                                   </div>
//                                 </div>
//                               )}
//                             </div>
//                           </td>
//                           <td className="icons">
//                             <span className="actions-spans item_s_icons">
//                               <span
//                                 className="arrow-btn"
//                                 onClick={() => handleShowBody(_id)}
//                               >
//                                 <img
//                                   src={showBody[_id] ? arrowUp : arrowDown}
//                                   alt="arrow down"
//                                 />
//                               </span>
//                               <span
//                                 className="receipt"
//                                 onClick={() => {
//                                   dispatch(generateReceipt(_id));
//                                 }}
//                               >
//                                 <img src={downloadIcon} alt="download" />
//                               </span>

//                               <Popover
//                                 content={() => shareReceiptContent(sale)}
//                                 title="Share Receipt via:"
//                                 trigger="click"
//                                 open={openPopoverId === _id}
//                                 onOpenChange={(newOpen) =>
//                                   handleOpenChange(newOpen, _id)
//                                 }
//                               >
//                                 <Button>
//                                   <span className="receipt noclick">
//                                     <img src={shareIcon} alt="print" />
//                                   </span>
//                                 </Button>
//                               </Popover>

//                               <span
//                                 className="receipt"
//                                 onClick={() => {
//                                   dispatch(printReceipt(_id, sale));
//                                 }}
//                               >
//                                 <img src={printIcon} alt="print" />
//                               </span>
//                               {admin ||
//                               currentUser?.permissions?.editproducts ? (
//                                 <Link
//                                   to={`/inventory/sales-checkin-new?id=${_id}`}
//                                 >
//                                   <span
//                                     className="return_icon"
//                                     onClick={() => {
//                                       setReturnModal(!returnModal);
//                                     }}
//                                   >
//                                     <img src={returnIcon} alt="print" />
//                                   </span>
//                                 </Link>
//                               ) : (
//                                 ""
//                               )}
//                             </span>
//                           </td>
//                         </tr>
//                         {showBody[_id] && (
//                           <>
//                             <tr>
//                               <td colSpan="9">
//                                 <div className="text_align_left sold_by_text">
//                                   <h4>Sold By: {user?.name}</h4>
//                                 </div>
//                               </td>
//                             </tr>
//                             <tr className="dynamic-content">
//                               <td colSpan="9">
//                                 <div className="sales_page_items_details">
//                                   <table>
//                                     <thead className="dynamically-display-header">
//                                       <tr>
//                                         <th>Product Name</th>
//                                         <th>Warehouse</th>
//                                         <th>Selling Price</th>
//                                         <th>Cost Price</th>
//                                         <td>Quantity</td>
//                                         <th>Profit</th>
//                                       </tr>
//                                     </thead>
//                                     <tbody>
//                                       {items &&
//                                         items.map((item, idx) => {
//                                           return (
//                                             <tr
//                                               key={idx}
//                                               className="dynamically-display-body"
//                                             >
//                                               <td>
//                                                 <Tooltip title={item?.name}>
//                                                   {shortenText(item?.name, 16)}
//                                                 </Tooltip>
//                                               </td>
//                                               <td>
//                                                 <Tooltip
//                                                   title={
//                                                     item.warehouse
//                                                       ? item.warehouse
//                                                       : ""
//                                                   }
//                                                 >
//                                                   {shortenText(
//                                                     item.warehouse
//                                                       ? item.warehouse
//                                                       : "",
//                                                     16
//                                                   )}
//                                                 </Tooltip>
//                                               </td>
//                                               <td>{formatter(item.price)}</td>
//                                               <td>
//                                                 {admin ||
//                                                 currentUser?.permissions
//                                                   ?.seeBusinessFinances
//                                                   ? formatter(item.cost)
//                                                   : "Unavailable"}
//                                               </td>
//                                               <td>{item.quantity}</td>
//                                               <td>
//                                                 {admin ||
//                                                 currentUser?.permissions
//                                                   ?.seeBusinessFinances
//                                                   ? formatter(
//                                                       item.price *
//                                                         item.quantity -
//                                                         item.cost *
//                                                           item.quantity
//                                                     )
//                                                   : "Unavailable"}
//                                               </td>
//                                             </tr>
//                                           );
//                                         })}
//                                     </tbody>
//                                   </table>
//                                 </div>
//                               </td>
//                             </tr>
//                           </>
//                         )}
//                       </React.Fragment>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             )}
//           </div>
//           <ReactPaginate
//             breakLabel="..."
//             nextLabel=">"
//             onPageChange={handlePageClick}
//             pageRangeDisplayed={2}
//             pageCount={pageCount}
//             previousLabel="<"
//             renderOnZeroPageCount={null}
//             containerClassName="pagination"
//             pageLinkClassName="page-num"
//             previousLinkClassName="page-num"
//             nextLinkClassName="page-num"
//             activeLinkClassName="activePageClass"
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default NewSalesList;



import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "../productList/productList.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_NEW_SALES,
  selectFilteredNewSales,
} from "../../../redux/features/product/filterSlice";
import {
  CALC_PROFIT_VALUE,
  CALC_SOLD_VALUE,
  generateReceipt,
  getCheckouts,
  printReceipt,
  updateDeliveryStatus,
} from "../../../redux/features/cart/cartSlice";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { Link } from "react-router-dom";
import arrowDown from "../../../assets/home/arrowdown.svg";
import arrowUp from "../../../assets/home/arrowUp.svg";
import downloadIcon from "../../../assets/home/downloadIcon.svg";
import printIcon from "../../../assets/home/printIcon.svg";
import shareIcon from "../../../assets/home/shareIcon.svg";
import whatsappIcon from "../../../assets/home/whatsapp-icon2.svg";
import emailIcon from "../../../assets/home/email-icon2.svg";
import returnIcon from "../../../assets/home/return.svg";
import "./salesList.css";
import DatePicker from "../../datePicker/DatePicker";
import InventoryHeader from "../../inventoryHeader/InventoryHeader";
import { Button, Popover, Tooltip } from "antd";
import { selectUser } from "../../../redux/features/auth/authSlice";
import ReturnFunction from "./ReturnFunction";
import StatisticsHeader from "../../inventoryHeader/StatisticsHeader";
import {
  sendReceiptEmail,
  shareReceipt,
  verifyBusinessEmail,
} from "../../../services/authService";
import { toast } from "react-toastify";
import axios from "axios";
import fileDownload from "js-file-download";
// import printReceipt from "../../../utils/print.ts";

const NewSalesList = ({ isLoading, admin, checkouts }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const filteredNewSales = useSelector(selectFilteredNewSales);
  const currentUser = useSelector(selectUser);
  const [showBody, setShowBody] = useState({});
  const [returnModal, setReturnModal] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [popOverCurrentSale, setPopOverCurrentSale] = useState(null);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(3);
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const handleOpenChange = (newOpen, id) => {
    setOpenPopoverId(newOpen ? id : null);
  };
  const [deliveryStatusDropdown, setDeliveryStatusDropdown] = useState(null);

  // console.log("currentUser", currentUser);

  const hide = () => {
    setOpenPopoverId(null);
  };

  function handleShowBody(id) {
    setShowBody((prev) => {
      const newState = { ...prev };
      newState[id] = !newState[id];
      return newState;
    });
  }

  useEffect(() => {
    dispatch(CALC_SOLD_VALUE(checkouts));
    dispatch(CALC_PROFIT_VALUE(checkouts));
  }, [dispatch, checkouts]);

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredNewSales.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredNewSales.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredNewSales]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredNewSales.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    dispatch(FILTER_NEW_SALES({ checkouts, search }));
  }, [checkouts, search, dispatch]);

  useEffect(() => {
    if (search) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(filteredNewSales.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredNewSales.length / itemsPerPage));
    } else {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(filteredNewSales.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredNewSales.length / itemsPerPage));
    }
  }, [filteredNewSales, itemOffset, itemsPerPage, search]);

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const sendReceiptToPrinter = (id, sale) => {};

  const sendReceiptToEmail = async (sale) => {
    const email = currentUser?.email;
    if (!email) {
      return;
    }

    // console.log("PopOverr id", openPopoverId);
    // console.log("PopOverr current sale", sale);

    const formData = {
      toEmail: sale.customer.email,
      sale: sale,
    };

    handleOpenChange(false, openPopoverId);
    setVerifyingEmail(true);
    try {
      handleOpenChange(false, openPopoverId);
      const response = await sendReceiptEmail(formData);
      setVerifyingEmail(false);
      toast.success(response.message);
      console.log("response", response);
    } catch (error) {
      setVerifyingEmail(false);
      console.log("error", error);
    }

    // window.open(`mailto:${email}?subject=${subject}&body=${body}`);
  };

  const verifyEmailSendGrid = async () => {
    setVerifyingEmail(true);
    try {
      const data = await verifyBusinessEmail();
      toast.success("Kindly check your email to verify your email address");
      handleOpenChange(false, openPopoverId);
      setVerifyingEmail(false);
    } catch (error) {
      setVerifyingEmail(false);
      toast.error(error.message);
      console.log("error", error);
    }
  };

  const shareReceiptContent = (sale) => {
    const message = `Thank you for your purchase!\n\nPlease find your receipt attached.`;

    const shareViaWhatsApp = async () => {
      handleOpenChange(false, openPopoverId);
      // setVerifyingEmail(true);
      try {
        const receipt = await shareReceipt(sale, sale._id);

        const receiptFile = new File([receipt], "receipt.pdf", {
          type: "application/pdf",
        });

        // console.log("receiptFile", receiptFile);
        if (
          navigator.canShare &&
          navigator.canShare({ files: [receiptFile] })
        ) {
          await navigator.share({
            title: "Sales Receipt",
            text: message,
            files: [receiptFile],
          });
        } else {
          // Fallback for unsupported browsers
          toast.error("File sharing is not supported on this device.");
        }
      } catch (error) {
        console.log("Error sharing:", error);
        toast.error("Failed to share the receipt.");
      }
    };

    return (
      <div className="share-receipt-content">
        <span>
          Verify your email to send emails, click{" "}
          <span onClick={verifyEmailSendGrid}>here</span>
        </span>
        <a
          onClick={() => sendReceiptToEmail(sale)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={emailIcon} alt="email" />
          <span className="share_text">Email</span>
        </a>
        <a onClick={shareViaWhatsApp} target="_blank" rel="noopener noreferrer">
          <img src={whatsappIcon} alt="whatsapp" />
          <span className="share_text">WhatsApp/Other channels</span>
        </a>
      </div>
    );
  };

  const handleChangeDeliveryStatus = async (id, newStatus) => {
    const formData = {
      id: id,
      status: newStatus,
    };

    if (currentUser?.permissions?.sellProducts) {
      try {
        const response = await dispatch(updateDeliveryStatus(formData));
        // console.log("response", response.meta.requestStatus);
        dispatch(getCheckouts({}));
        toast.success("Delivery status updated successfully.");
        setDeliveryStatusDropdown(null);
      } catch (error) {
        console.log("error", error);
        toast.error("Failed to update delivery status.");
      }
    } else {
      toast.error("You do not have permission to update delivery status.");
    }
  };

  const toggleDeliveryStatusDropdown = (id) => {
    setDeliveryStatusDropdown((prev) => (prev === id ? null : id));
  };

  const handleDetectMobileScreen = () => {
    if (window.innerWidth <= 500) {
      setPageRangeDisplayed(2); // For mobile devices
    } else {
      setPageRangeDisplayed(3); // For larger screens
    }
  };

  useEffect(() => {
    handleDetectMobileScreen(); // Set initial value
    window.addEventListener("resize", handleDetectMobileScreen);
    return () => {
      window.removeEventListener("resize", handleDetectMobileScreen);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        deliveryStatusDropdown &&
        !event.target.closest(".delivery-status-options") &&
        !event.target.closest(".delivery-status-dropdown")
      ) {
        setDeliveryStatusDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [deliveryStatusDropdown]);

  return (
    <>
      <div className="product-list sales-page">
        <div className="table">
          <InventoryHeader
            search={search}
            handleSearchChange={(e) => setSearch(e.target.value)}
          />
          {returnModal && (
            <div className="return_container">
              <ReturnFunction
                handleCancel={() => {
                  setReturnModal(!returnModal);
                }}
                items={currentItems}
                admin={admin}
                currentUser={currentUser}
              />
            </div>
          )}

          <StatisticsHeader
            checkouts={checkouts}
            admin={admin}
            search={search}
            currentUser={currentUser}
          />

          {isLoading && <SpinnerImg />}
          {verifyingEmail && <SpinnerImg />}

          <div className="">
            {!isLoading && checkouts.length === 0 ? (
              <p className="no-products-p">
                -- All checkouts will appear here...
              </p>
            ) : (
              <table className="salesList-table">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Items</th>
                    <th>Customer</th>
                    <th>Payment Type</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Delivery</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((sale, index) => {
                    const {
                      customer,
                      items,
                      user,
                      createdAt,
                      _id,
                      payment,
                      deliveryStatus,
                      orderId,
                    } = sale;
                    const format = "DD-MM-YYYY h:mmA";
                    const formattedDate = moment(createdAt).format(format);
                    const formattedDeliveryDate = moment(
                      deliveryStatus.date
                    ).format(format);
                    const totalAmount = items.reduce(
                      (total, item) => total + item.price * item.quantity,
                      0
                    );
                    return (
                      <React.Fragment key={index}>
                        <tr className={showBody[_id] ? "show_background" : ""}>
                          <td onClick={() => handleShowBody(_id)}>
                            {orderId ? orderId : index + 1}
                          </td>
                          <td onClick={() => handleShowBody(_id)}>
                            <div className="item_s_date">{formattedDate}</div>
                          </td>
                          <td onClick={() => handleShowBody(_id)}>
                            {items.length}
                          </td>
                          <td>
                            <div className="item_name">
                              {customer?.name && (
                                <Tooltip title={customer?.name}>
                                  {shortenText(customer?.name, 14)}
                                </Tooltip>
                              )}
                            </div>
                          </td>

                          <td onClick={() => handleShowBody(_id)}>
                            <div className="item_name">
                              {payment?.paymentType}
                            </div>
                          </td>
                          <td onClick={() => handleShowBody(_id)}>
                            {payment?.paymentStatus}
                          </td>
                          <td>
                            <div className="item_name">
                              {formatter(totalAmount)}
                            </div>
                          </td>
                          <td>
                            <div
                              className="delivery-status-dropdown"
                              onClick={() => toggleDeliveryStatusDropdown(_id)}
                            >
                              {deliveryStatus?.status === "pickedup"
                                ? "picked up"
                                : deliveryStatus?.status}
                              <span>{formattedDeliveryDate}</span>
                              {deliveryStatusDropdown === _id && (
                                <div className="delivery-status-options">
                                  <div
                                    onClick={() =>
                                      handleChangeDeliveryStatus(_id, "pending")
                                    }
                                    className={
                                      deliveryStatus?.status === "pending"
                                        ? "option active"
                                        : "option"
                                    }
                                  >
                                    Pending
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleChangeDeliveryStatus(
                                        _id,
                                        "delivered"
                                      )
                                    }
                                    className={
                                      deliveryStatus?.status === "delivered"
                                        ? "option active"
                                        : "option"
                                    }
                                  >
                                    Delivered
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleChangeDeliveryStatus(
                                        _id,
                                        "pickedup"
                                      )
                                    }
                                    className={
                                      deliveryStatus?.status === "pickedup"
                                        ? "option active"
                                        : "option"
                                    }
                                  >
                                    Picked up
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="icons">
                            <span className="actions-spans item_s_icons">
                              <span
                                className="arrow-btn"
                                onClick={() => handleShowBody(_id)}
                              >
                                <img
                                  src={showBody[_id] ? arrowUp : arrowDown}
                                  alt="arrow down"
                                />
                              </span>
                              <span
                                className="receipt"
                                onClick={() => {
                                  dispatch(generateReceipt(_id));
                                }}
                              >
                                <img src={downloadIcon} alt="download" />
                              </span>

                              <Popover
                                content={() => shareReceiptContent(sale)}
                                title="Share Receipt via:"
                                trigger="click"
                                open={openPopoverId === _id}
                                onOpenChange={(newOpen) =>
                                  handleOpenChange(newOpen, _id)
                                }
                              >
                                <Button>
                                  <span className="receipt noclick">
                                    <img src={shareIcon} alt="print" />
                                  </span>
                                </Button>
                              </Popover>

                              <span
                                className="receipt"
                                onClick={() => {
                                  dispatch(printReceipt(_id, sale));
                                }}
                              >
                                <img src={printIcon} alt="print" />
                              </span>
                              {admin ||
                              currentUser?.permissions?.editproducts ? (
                                <Link
                                  to={`/inventory/sales-checkin-new?id=${_id}`}
                                >
                                  <span
                                    className="return_icon"
                                    onClick={() => {
                                      setReturnModal(!returnModal);
                                    }}
                                  >
                                    <img src={returnIcon} alt="print" />
                                  </span>
                                </Link>
                              ) : (
                                ""
                              )}
                            </span>
                          </td>
                        </tr>
                        {showBody[_id] && (
                          <>
                            <tr>
                              <td colSpan="9">
                                <div className="text_align_left sold_by_text">
                                  <h4>Sold By: {user?.name}</h4>
                                </div>
                              </td>
                            </tr>
                            <tr className="dynamic-content">
                              <td colSpan="9">
                                <div className="sales_page_items_details">
                                  <table>
                                    <thead className="dynamically-display-header">
                                      <tr>
                                        <th>Product Name</th>
                                        <th>Warehouse</th>
                                        <th>Selling Price</th>
                                        <th>Cost Price</th>
                                        <td>Quantity</td>
                                        <th>Profit</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {items &&
                                        items.map((item, idx) => {
                                          return (
                                            <tr
                                              key={idx}
                                              className="dynamically-display-body"
                                            >
                                              <td>
                                                <Tooltip title={item?.name}>
                                                  {shortenText(item?.name, 16)}
                                                </Tooltip>
                                              </td>
                                              <td>
                                                <Tooltip
                                                  title={
                                                    item.warehouse
                                                      ? item.warehouse
                                                      : ""
                                                  }
                                                >
                                                  {shortenText(
                                                    item.warehouse
                                                      ? item.warehouse
                                                      : "",
                                                    16
                                                  )}
                                                </Tooltip>
                                              </td>
                                              <td>{formatter(item.price)}</td>
                                              <td>
                                                {admin ||
                                                currentUser?.permissions
                                                  ?.seeBusinessFinances
                                                  ? formatter(item.cost)
                                                  : "Unavailable"}
                                              </td>
                                              <td>{item.quantity}</td>
                                              <td>
                                                {admin ||
                                                currentUser?.permissions
                                                  ?.seeBusinessFinances
                                                  ? formatter(
                                                      item.price *
                                                        item.quantity -
                                                        item.cost *
                                                          item.quantity
                                                    )
                                                  : "Unavailable"}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="activePageClass"
          />
        </div>
      </div>
    </>
  );
};

export default NewSalesList;
